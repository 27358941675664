<template>
  <div>
    <div v-if="!loading">
      <b-form-checkbox
        v-model="autoCreateContactThreadEnabled"
        variant="primary"
        class="mb-50"
      >
        {{ $t('TurnOnDefaultAssignmentOfThreadToNewContact') }}
      </b-form-checkbox>
      <b-form-group :label="$t('ChooseDefaultFunnel')">
        <v-select
          v-model="autoCreateContactThreadFunnelType"
          :disabled="!autoCreateContactThreadEnabled"
          :options="funnels"
          :reduce="item => item.id"
          label="name"
          @option:selected="getFunnelStatuses($event)"
        />
      </b-form-group>
      <b-form-group :label="$t('ChooseDefaultStatus')">
        <v-select
          v-model="autoCreateContactThreadFunnelTypePoint"
          :disabled="!autoCreateContactThreadEnabled && !autoCreateContactThreadFunnelType"
          :options="funnelStatuses"
          :reduce="item => item.id"
          label="name"
        />
      </b-form-group>

      <b-form-group :label="$t('DefaultName')">
        <b-form-input v-model="autoCreateContactThreadName"
                      :disabled="!autoCreateContactThreadEnabled"
        />
      </b-form-group>

      <b-button
        size="sm"
        variant="primary"
        :disabled="(autoCreateContactThreadEnabled && !autoCreateContactThreadFunnelTypePoint)"
        @click="save"
      >
        {{ $t('Save') }}
      </b-button>
    </div>
    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        class="m-5"
      />
    </div>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import { GET_PRIVATE_SETTINGS } from '@/@constants/mutations'
import vSelect from 'vue-select'

export default {
  name: 'ContactThreadGeneralSettings',
  components: { vSelect },
  data: () => ({
    areChangesSaved: true,
    loading: false,

    funnels: [],
    funnelStatuses: [],

    autoCreateContactThreadEnabled: false,
    autoCreateContactThreadFunnelTypePoint: null,
    autoCreateContactThreadFunnelType: null,
    autoCreateContactThreadName: '',
  }),
  watch: {
    autoCreateContactThreadEnabled(newValue) {
      // const {
      //   autoCreateContactThreadEnabled,
      //   autoCreateContactThreadFunnelType,
      //   autoCreateContactThreadFunnelTypePoint,
      // } = this

      // this.areChangesSaved = !(autoCreateContactThreadEnabled && (autoCreateContactThreadFunnelType !== '' || autoCreateContactThreadFunnelTypePoint !== ''))

      if (!newValue) {
        this.autoCreateContactThreadFunnelTypePoint = null
        this.autoCreateContactThreadName = null
        this.autoCreateContactThreadFunnelType = null
      }
    },
    autoCreateContactThreadFunnelType: {
      deep: true,
      handler(newValue, oldValue) {
        if (this.getObjectId(oldValue) && this.getObjectId(newValue) !== this.getObjectId(oldValue)) {
          this.autoCreateContactThreadFunnelTypePoint = null
        }
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      await this.getGlobalSettings()
      await this.getFunnels()
      await this.getFunnelStatuses(this.autoCreateContactThreadFunnelType?.id || this.funnels[0]?.id)
      this.loading = false
    },
    async getGlobalSettings() {
      try {
        const reponse = await this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)

        Object.assign(this, reponse.data.item)
        if (reponse.data.item?.autoCreateContactThreadFunnelTypePoint?.contactThreadFunnelType) {
          this.autoCreateContactThreadFunnelType = reponse.data.item?.autoCreateContactThreadFunnelTypePoint?.contactThreadFunnelType
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async getFunnels() {
      try {
        const response = await axiosIns.get('1/settings/contactThreadFunnelTypes', {
          params: { fields_load: this.$fields.SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' },
        })

        if (response?.data?.data?.items) this.funnels = response.data.data.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async getFunnelStatuses(funnelId) {
      if (!funnelId) return

      try {
        const response = await axiosIns.get('1/settings/contactThreadFunnelTypePoints', {
          params: { 'eq_contactThreadFunnelType-id': this.getObjectId(funnelId), fields_load: this.$fields.SETTINGS_FUNNEL_TYPE_POINTS, orderBy: 'position.asc' },
        })

        if (response?.data?.data?.items) this.funnelStatuses = response.data.data.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        // const {
        //   autoCreateContactThreadEnabled,
        //   autoCreateContactThreadFunnelType,
        //   autoCreateContactThreadFunnelTypePoint,
        // } = this

        // this.areChangesSaved = !(autoCreateContactThreadEnabled && (autoCreateContactThreadFunnelType !== '' || autoCreateContactThreadFunnelTypePoint !== ''))
      }
    },

    async save() {
      try {
        this.loading = true
        const payload = {
          id: 1,
          autoCreateContactThreadEnabled: this.autoCreateContactThreadEnabled,
          autoCreateContactThreadName: this.autoCreateContactThreadName,
          autoCreateContactThreadFunnelTypePoint: this.getObjectId(this.autoCreateContactThreadFunnelTypePoint),
        }

        await axiosIns.patch('1/settings/global', payload)
        // this.areChangesSaved = true
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        // this.areChangesSaved = true
        this.loading = false
      }
    },
  },
}

// Kontakt jest w statusie
// Wątek jest w Lejku o statusie
// Zmiana checklisty w x zaznaczono y
// Jeżeli wysłano ofertę
</script>

<style scoped>

</style>
